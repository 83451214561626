// import external dependencies
import 'jquery'; // Deprecated below
// import '@babel/polyfill'
// Alternative
// // for core-js v2:
// import "core-js/fn/array/flat-map";
// // for core-js v3:
// import "core-js/features/array/flat-map";
// Should be good 

import "core-js/stable";
import "regenerator-runtime/runtime";

require('es6-promise').polyfill();

require('isomorphic-fetch'); // Import everything from autoload


import './autoload/_bootstrap.js'; // import local dependencies

import Router from './util/Router';
import common from './routes/common';
import blog from './routes/blog';
import shop from './routes/shop';
import product from './routes/product';
import faq from './routes/faq';
import home from './routes/home';
/** Populate Router instance with DOM routes */

var routes = new Router({
  // All pages
  common: common,
  shop: shop,
  product: product,
  faq: faq,
  home: home,
  // The Blog page, where all posts are shown
  blog: blog
}); // Load Events

jQuery(document).ready(function () {
  return routes.loadEvents();
});