import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.replace.js";
export default {
  init: function init() {
    var switcher = document.querySelector('.wpml-ls-link');
    var lang = window.location.pathname.split('/en/').length === 2 ? 'en' : 'fr';
    var langToSwitch = lang === 'en' ? 'fr' : 'en';
    switcher.href = window.location.pathname.replace(lang, langToSwitch) + window.location.hash;

    if ('onhashchange' in window) {
      window.onhashchange = locationHashChanged;
    }

    function locationHashChanged() {
      switcher.href = window.location.pathname.replace(lang, langToSwitch) + window.location.hash;
    }
  }
};