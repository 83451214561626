import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.parse-int.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import inViewport from 'in-viewport';
import { translate } from '../react/utils/helpers';
import { LANG } from '../react/utils/constant';
var _window$blog = window.blog,
    requestUrl = _window$blog.requestUrl,
    nonce = _window$blog.nonce;
var offset = 5;
var fetchingPosts = false; // WTF IS THAT ?????!!!
//rko code

$(document).ready(function () {
  $("nav.pagination_news ul.page-numbers li").addClass("number_pagination");
  $("nav.pagination_news ul.page-numbers li a.prev").removeClass("page-numbers");
  $("nav.pagination_news ul.page-numbers li a.next").removeClass("page-numbers");
  $("nav.pagination_news ul.page-numbers li a.prev").parent().removeClass("number_pagination");
  $("nav.pagination_news ul.page-numbers li a.next").parent().removeClass("number_pagination");
  $("nav.pagination_news ul.page-numbers li a.prev").parent().addClass("prev_button");
  $("nav.pagination_news ul.page-numbers li a.next").parent().addClass("next_button");
  $("nav.pagination_news ul.page-numbers li.number_pagination").after('<span class="news_point">&#183;</span>');
  $("nav.pagination_news ul.page-numbers .news_point").last().css("display", "none");
});
export default {
  init: function init() {
    $(window).on('scroll', function () {
      fetchPosts();
    });
    $('#posts-container').on('click', '.btn-read-more', handleClickButton);
  },
  finalize: function finalize() {
    setTimeout(function () {
      fetchPosts();
    }, 500);
  }
};

function loadMoreSectionIsInViewport() {
  return inViewport(document.querySelector('#load-more-section'));
}

function fetchPosts() {
  if (loadMoreSectionIsInViewport() && !fetchingPosts) {
    getPosts();
  }
}

function getPosts() {
  fetchingPosts = true;
  var dataSent = {
    action: 'blog_get_more_posts',
    nonce: nonce,
    posts_per_page: 1,
    offset: offset
  };
  $.post(requestUrl, dataSent).done(function (resp) {
    if (resp.data.trim()) {
      $('#posts-container').append(resp.data);
      offset += dataSent.posts_per_page;
      fetchingPosts = false;
      fetchPosts();
    }
  });
}

var getUrlParameter = function getUrlParameter(sParam) {
  var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
    }
  }
};

var textConfirm = translate('Email saved with success', 'Courriel enregistré avec succès');
var textSuccess = translate('Email saved with success', 'Courriel enregistré avec succès');
var textError = translate('Error while saving, try again please.', "Erreur lors de l'engeristrement, veuillez réessayer.");

if (getUrlParameter('CIMessage') === 'success') {
  $('.CIMessage').text(textSuccess);
  scrollToAnchor('home-newsletter');
} else if (getUrlParameter('CIMessage') === 'confirm') {
  $('.CIMessage').text(textConfirm);
  scrollToAnchor('home-newsletter');
} else if (getUrlParameter('CIMessage') === 'error') {
  $('.CIMessage').text(textError);
  scrollToAnchor('home-newsletter');
}

function scrollToAnchor(aid) {
  var aTag = $('#' + aid);
  setTimeout(function () {
    $('html,body').animate({
      scrollTop: aTag.offset().top - 200
    }, 'slow');
  }, 2000);
}

function handleClickButton(event) {
  event.preventDefault();
  event.stopPropagation();
  var button = event.target;
  var id = parseInt(button.id, 10);
  var requestData = {
    action: 'blog_get_full_post',
    nonce: nonce,
    id: id
  };
  var $article = $('#post-' + id);
  var entrySummary = $article.find('.entry-summary'); //   const header = $article.find('header')

  button.disabled = true; //   header.toggleClass('loading')
  //   entrySummary.toggleClass('loading')
  //   $article.addClass('loading')

  var date = new Date();
  var now = date.getTime();

  if ($(button).hasClass('read-more')) {
    $.post(requestUrl, requestData).done(function (_ref) {
      var data = _ref.data;
      var newDate = new Date();
      var newNow = newDate.getTime();
      entrySummary.html(data);
      $article.toggleClass('closed');
      button.textContent = LANG === 'fr' ? 'Fermer' : 'Close';
      button.disabled = false;
      document.querySelector('#post-' + id).scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });

      if (newNow - now > 200) {// $article.removeClass('loading')
        // header.toggleClass('loading')
        // entrySummary.toggleClass('loading')
      } else {
        setTimeout(function () {//   $article.removeClass('loading')
          //   header.toggleClass('loading')
          //   entrySummary.toggleClass('loading')
        }, 200 - newNow - now);
      }
    });
  } else {
    // setTimeout(() => {
    entrySummary.html($article.find('.saved-excerpt')[0].innerHTML);
    button.disabled = false;
    button.textContent = LANG === 'fr' ? 'Lire au complet' : 'Read more';
    $article.toggleClass('closed'); //   $article.removeClass('loading')
    //   header.toggleClass('loading')
    //   entrySummary.toggleClass('loading')
    // }, 400)
  }

  $(button).toggleClass('read-more');
}