import { LANG } from '../react/utils/constant';
$('.faq-page-content .btn').on('click', function () {
  var textClose = LANG === 'fr' ? 'Fermer' : 'Close';
  var textOpen = LANG === 'fr' ? 'Lire plus' : 'Read more';

  if ($(this).attr('aria-expanded') === 'false') {
    $(this).html(textClose);
  } else {
    $(this).html(textOpen);
  }
});