export default {
  init: function init() {// const slug = document
    //   .querySelector('#product-page')
    //   .getAttribute('product-slug')
    // // console.log(slug)
    // document.querySelector('.product-root') &&
    //   ReactDOM.render(
    //     <SingleProductWoMatch slug={slug} />,
    //     document.querySelector('.product-root')
    //   )
  },
  finalize: function finalize() {// setTimeout(() => {
    //   fetchPosts()
    // }, 500)
  }
};