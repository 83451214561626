import "core-js/modules/es.array.map.js";
import "core-js/modules/es.string.ends-with.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

export default (function () {
  var _window$openingInform = window.openingInformation,
      requestUrl = _window$openingInform.requestUrl,
      nonce = _window$openingInform.nonce;

  var test = _toConsumableArray(document.querySelectorAll('.set-active-branch'));

  var title_page_why = document.getElementById("title_page_why"); //Array.from(document.querySelectorAll('.set-active-branch')).

  test.map(function (element) {
    return element.addEventListener('click', function (event) {
      event.preventDefault();
      event.stopPropagation();

      if (this.classList.contains('selected-branch')) {
        return;
      }

      document.querySelector('#dropdown-branches').classList.add('fetching');

      if (document.querySelector('.selected-branch')) {
        document.querySelector('.selected-branch').classList.remove('selected-branch');
      }

      this.classList.add('selected-branch');
      $('#dropdown-branches').dropdown('toggle');
      var dataSent = {
        action: 'common_set_store_default',
        nonce: nonce,
        id: this.dataset.id
      };
      $.post(requestUrl, dataSent).done(function (data) {
        // console.log(data.data);
        if (window.location.pathname.endsWith('/nous-joindre/') || window.location.pathname.endsWith('/contact/')) {
          window.location.reload();
        }

        document.querySelector('#selected-branch-name').innerHTML = data.data.title;

        if (title_page_why) {
          document.querySelector('#title_page_why').innerHTML = data.data.title;
        }

        var time = data.data.open_until;

        if (document.documentElement.lang == "fr-FR") {
          var H = +time.substr(0, 2);
          time = H + "H" + time.substr(2, 3);
          document.querySelector('#selected-branch-hour').innerHTML = time.replace(':', '');
        } else {
          var _H = +time.substr(0, 2);

          var h = _H % 12 || 12;
          var ampm = _H < 12 ? " AM" : " PM";
          time = h + time.substr(2, 3) + ampm;
          document.querySelector('#selected-branch-hour').innerHTML = time;
        }

        document.querySelector('#title_address_sani_depot_drop').innerHTML = data.data.title;
        document.querySelector('#address_succursale').innerHTML = data.data.address;
        document.querySelector('#country_succursale').innerHTML = data.data.country;
        document.querySelector('#province_succursale').innerHTML = "(" + data.data.province + ")";
        document.querySelector('#code_postal_succursale').innerHTML = data.data.postal_code;
        document.querySelector('#city_succursale').innerHTML = data.data.city;
        document.querySelector('#selected-branch-phone').innerHTML = data.data.phone;

        if (data.data.closed) {
          toggleHideShow('.closed', '#dropdown-branches', true);
          toggleHideShow('.open-until', '#dropdown-branches', false);
        } else {
          toggleHideShow('.closed', '#dropdown-branches', false);
          toggleHideShow('.open-until', '#dropdown-branches', true);
        }

        document.querySelector('#dropdown-branches').classList.remove('fetching');
      });
    });
  });

  function toggleHideShow(element, parent, display) {
    document.querySelector(parent).querySelector(element).classList.remove(display ? 'hide' : 'show');
    document.querySelector(parent).querySelector(element).classList.add(display ? 'show' : 'hide');
  }
  /* Display a backdrop behind Branches Dropdown when opened */


  $('.branches-dropdown').on('show.bs.dropdown', function () {
    document.querySelector('#branches-backdrop').classList.add('dropdown-open');
    setTimeout(function () {
      document.querySelector('#branches-backdrop').classList.add('display');
    }, 50);
  });
  /* Hide the backdrop behind Branches Dropdown when opened */

  $('.branches-dropdown').on('hide.bs.dropdown', function () {
    document.querySelector('#branches-backdrop').classList.remove('display');
    setTimeout(function () {
      document.querySelector('#branches-backdrop').classList.remove('dropdown-open');
    }, 300);
  });

  if ($('#whole_block')) {
    $('#whole_block').slick({
      autoplay: true,
      autoplaySpeed: 5000,
      arrows: true,
      dots: true,
      prevArrow: '<i class="slick-prev fa fa-angle-left"></i>',
      nextArrow: '<i class="slick-next fa fa-angle-right"></i>',
      responsive: [{
        breakpoint: 677,
        settings: {
          arrows: false
        }
      }]
    });
  }
});