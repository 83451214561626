import "core-js/modules/es.string.starts-with.js";

if (!String.prototype.startsWith) {
  String.prototype.startsWith = function (searchString, position) {
    return this.substr(position || 0, searchString.length) === searchString;
  };
}

export var API_URL = document.querySelector('#API_URL').value;
export var SITE_NAME = document.querySelector('#SITE_NAME').value;
export var MAIN_COLOR = document.querySelector('#MAIN_COLOR').value;
export var DEFAULT_LANG = document.querySelector('#DEFAULT_LANG').value;
var pathname = window.location.pathname;
export var LANG = pathname.startsWith('/fr/') ? 'fr' : 'en';