import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.parse-int.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.ends-with.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.join.js";
import _ from 'lodash';
import _startsWith from 'lodash/startsWith';
import _isNaN from 'lodash/isNaN';
import { LANG, API_URL } from './constant';
/**
 * Return the absolute asset URL
 * @param {string} relativePath
 * @returns {string}
 */

export var assetPath = function assetPath(relativePath) {
  return API_URL + relativePath;
};
/**
 * Return a truncated string to the length desired
 * @param {string} text
 * @param {number} length default 200
 * @returns {string} truncated string with <br> and <p> removed
 */

export var excerpt = function excerpt(text) {
  var length = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 200;
  return _.chain(text).replace(new RegExp('&amp;lt;', 'g'), '<').replace(new RegExp('&amp;gt;', 'g'), '>').replace(new RegExp('<br />', 'g'), ' ').replace(new RegExp('<p>', 'g'), ' ').replace(new RegExp('</p>', 'g'), ' ').replace(new RegExp('&amp;agrave;', 'g'), 'à').replace(new RegExp('&agrave;', 'g'), 'à').replace(new RegExp('&amp;eacute;', 'g'), 'é').replace(new RegExp('&eacute;', 'g'), 'é').replace(new RegExp('&ccedil;', 'g'), 'ç').replace(new RegExp('&ecirc;', 'g'), 'ê').trim().truncate({
    length: length
  }).value();
};
/**
 * Return a cleaned string
 * @param {string} text
 * @returns {string} cleaned string
 */

export var cleanHTML = function cleanHTML(text) {
  return text.replace(new RegExp('&nbsp;', 'g'), 'fdf').replace(new RegExp('&amp;lt;', 'g'), '<').replace(new RegExp('&amp;gt;', 'g'), '>').replace(new RegExp('<br />', 'g'), ' ').replace(new RegExp('<p>', 'g'), ' ').replace(new RegExp('</p>', 'g'), ' ').replace(new RegExp('&amp;agrave;', 'g'), 'à').replace(new RegExp('&agrave;', 'g'), 'à').replace(new RegExp('&amp;eacute;', 'g'), 'é').replace(new RegExp('&eacute;', 'g'), 'é').replace(new RegExp('&ccedil;', 'g'), 'ç').replace(new RegExp('&ecirc;', 'g'), 'ê').trim();
};
/**
 * Create an object from URL parameters
 * @param {object} params
 * @returns {object}
 */

export var objectifyParameters = function objectifyParameters(params) {
  var url = '?';
  var myObject = {};
  var key = '';
  var index = 1;

  for (key in params) {
    if (_startsWith(key, 'param')) {
      if (url !== '?') {
        url += '&';
      }

      var param = params['param' + index];
      var value = params['value' + index];

      if (param === 'display' || param === 'page') {
        value = parseInt(value, 10);
      }

      if (param === 'suppliers' || param === 'certifications') {
        value = value.split(/[,/+]/);
      }

      if (param === 'category' || param === 'families') {
        value = value.split(/[,/+]/); // value = value[value.length - 1]
      }

      myObject[param] = value;
      index++;
    }
  } //   console.log(myObject)


  return myObject;
};
/**
 * Return the parametized URL to fetch data to the API
 * @param {object} parameters
 * @returns {string}
 */

export var parametizeURL = function parametizeURL(parameters) {
  var key = '';
  var url = '?';

  for (key in parameters) {
    var value = parameters[key];

    if (url !== '?') {
      url += '&';
    }

    if (key === 'category') {
      key = renameKeyForAPI(key, value.length);
      value = parseInt(value[value.length - 1]);

      if (_isNaN(value)) {
        return null;
      }
    } else {
      key = renameKeyForAPI(key);
    }

    value = renameValueForAPI(value);
    url += getKeyValueForAPI(key, value);
  }

  return url;
};
/**
 * Return a key value string to fetch data to the API
 * @param {string} key
 * @param {string|Array} value
 * @returns {string}
 */

var getKeyValueForAPI = function getKeyValueForAPI(key, value) {
  var keyValue = '';

  if (key.endsWith('[]')) {
    value.forEach(function (_value) {
      keyValue += key + '=' + _value + '&';
    });
    keyValue = keyValue.slice(0, -1);
  } else {
    keyValue = key + '=' + value;
  }

  return keyValue;
};
/**
 * Rename URL key parameters to accommodate API
 * @param {string} key
 * @param {number} categoryType
 * @returns {string}
 */


var renameKeyForAPI = function renameKeyForAPI(key) {
  var categoryType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

  if (key === 'category') {
    key = categoryType === 1 ? 'cate_ids' : categoryType === 2 ? 'scate_ids' : 'sscate_ids';
  }

  if (key === 'display') {
    key = 'per';
  }

  if (key === 'order') {
    key = 'order_by';
  }

  if (key === 'certifications') {
    key = 'certification_ids[]';
  }

  if (key === 'suppliers') {
    key = 'supplier_ids[]';
  }

  if (key === 'families') {
    key = 'family_ids[]';
  }

  if (key === 'search') {
    key = 'q';
  }

  return key;
};
/**
 * Rename URL value parameters to accommodate API
 * @param {string} value
 * @returns {string}
 */


var renameValueForAPI = function renameValueForAPI(value) {
  if (value === 'name') {
    value = translateFieldName('name');
  }

  if (value === 'code') {
    value = translateFieldName('code');
  }

  return value;
};
/**
 * Slugify a string to use to prettify URLs
 * @param {string} string
 * @returns {string}
 */


export var slugify = function slugify(string) {
  return _.chain(string).toLower().deburr().kebabCase().value();
};
/**
 * Return the translated text according to the website language
 * @param {string} english
 * @param {string} french
 * @returns {string}
 */

export var translate = function translate(english) {
  var french = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return LANG === 'fr' && french !== '' ? french : english;
};
/**
 * Return the translated fieldName to simplify API usage.
 * @param {string} fieldName ex: name or short_code
 * @returns {string}
 */

export var translateFieldName = function translateFieldName(fieldName) {
  return fieldName + '_' + LANG;
};
var diacriticsMap = {
  a: 'á|à|ã|â|À|Á|Ã|Â',
  e: 'é|è|ê|É|È|Ê',
  i: 'í|ì|î|Í|Ì|Î',
  o: 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
  u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
  c: 'ç|Ç',
  n: 'ñ|Ñ'
};
/**
 * Replace whitespaces by dashes. Lower case the string. Remove diacritics.
 * @param {string} fieldName ex: name or short_code
 * @returns {string}
 */

export var convertStringToWPSlug = function convertStringToWPSlug(string) {
  string = string.toLowerCase();

  for (var pattern in diacriticsMap) {
    string = string.replace(new RegExp(diacriticsMap[pattern], 'g'), pattern);
  }

  return string.replace(/[^a-zA-Z0-9\s]/g, '').trim().replace(/\s+/g, '-');
};
/**
 * Return a new URL keeping existing parameters
 * @param {object} object
 * @param {string} param
 * @param {number} value
 * @param {boolean} forcePageToOne
 * @returns {string}
 */

export var getNewURL = function getNewURL(object, param, value) {
  var forcePageToOne = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

  var _object = _.clone(object);

  var key = '';
  var url = '/';

  if (value === '' || param === 'display' && value === '48' || param === 'page' && value === 1 || param === 'order' && value === 'code') {
    delete _object[param];
  } else {
    //   console.log(value);
    _object[param] = value;
  }

  if (forcePageToOne === true && _object['page'] !== undefined) {
    delete _object['page'];
  }

  for (key in _object) {
    var _value2 = _object[key];

    if (Array.isArray(_object[key])) {
      _value2 = _object[key].join('+');
    }

    url += key + '/' + _value2 + '/';
  }

  return url;
};
/**
 * Return a new object with
 * @param {object} object
 * @param {array} keys
 * @returns {object}
 */

export var removeKeyValueFromObject = function removeKeyValueFromObject(object) {
  var _object = _.clone(object);

  for (var _len = arguments.length, keys = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    keys[_key - 1] = arguments[_key];
  }

  keys.forEach(function (key) {
    delete _object[key];
  });
  return _object;
};
/***
 * Return the data stored in the local storage
 */

export var getCartLocalStorage = function getCartLocalStorage() {
  var lastUpdate = localStorage.getItem('lastUpdate');
  var now = Date.now();

  if (supTime(lastUpdate, now, 24 * 60 * 60 * 1000)) {
    localStorage.setItem('lastUpdate', now);
    return [];
  } else {
    localStorage.setItem('lastUpdate', now);
    return Array.isArray(JSON.parse(localStorage.getItem('cart'))) ? JSON.parse(localStorage.getItem('cart')) : [];
  }
};
export var countProductLocalStorage = function countProductLocalStorage(cart) {
  var count = 0;
  cart.forEach(function (product) {
    count += product.quantity;
  });
  return count;
};
export var setCartLocalStorage = function setCartLocalStorage(cart) {
  localStorage.setItem('cart', JSON.stringify(cart));
  localStorage.setItem('lastUpdate', Date.now());
  $('#cart-counter').trigger('change');
};
/** Clear local storage **/

export var clearLocalStorage = function clearLocalStorage() {
  localStorage.removeItem('cart');
  $('#cart-counter').trigger('change');
};
/**
 * Check if time > 24h
 * t1, t2 : timestamp
 * ttc : time to compare int (seconds)
 */

export var supTime = function supTime(t1, t2, ttc) {
  return t2 - t1 > ttc * 1000;
};